// //LOCAL URL
// const url = "http://192.168.1.8:1998"; 
// export const WebSocketUrl = "http://192.168.1.8:1998"; 

// //Staging URL
// const url = "https://staging.node.rival.finance"; //  url
// export const WebSocketUrl = "https://staging.node.rival.finance"; //  url
  
// //LIVE URL
const url = "https://node.rival.finance"; //  url
export const WebSocketUrl = "https://node.rival.finance";//  url
 


const ApiConfig = {
  login: `${url}/api/v1/user/login`,
  loginadmin: `${url}/api/v1/admin/login`,
  liquidity: `${url}/api/v1/admin/liquidityDashboard`,
  listAdminHotWallet: `${url}/api/v1/admin/listAdminHotWallet`,
  createUser: `${url}/api/v1/admin/createUser`,
  signup: `${url}/api/v1/user/register`,
  cancelSubscriptionData: `${url}/api/v1/user/listcancelledSubscription`,
  waitingList: `${url}/api/v1/admin/inviteUserListByAdmin `,
  uploadImageFile: `${url}/api/v1/user/uploadImageFile `,
  setup2FA: `${url}/api/v1/admin/setup2FA`,
  verify2FA: `${url}/api/v1/admin/verify2FA`,
  disable2FA: `${url}/api/v1/admin/disable2FA`,
  ApproveRejectInvite: `${url}/api/v1/admin/approveRejectInvite`,
  verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verifyOTP`,
  forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  changePasswordAdmin: `${url}/api/v1/admin/changePassword`,
  resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  profile: `${url}/api/v1/user/profile`,
  listInterestedUser: `${url}/api/v1/user/listInterestedUser`,
  getProfile: `${url}/api/v1/admin/adminProfile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  updateAdminProfile: `${url}/api/v1/admin/updateAdminProfile`,
  uploadImage: `${url}/api/v1/user/uploadImage`,
  allDataPackage: `${url}/api/v1/user/getAllDataPackage`,

  kyclist: `${url}/api/v1/admin/listkyc`,
  viewKyc: `${url}/api/v1/admin/viewKyc`,
  approveOrReject: `${url}/api/v1/admin/approveRejectKyc`,
  dashboardList: `${url}/api/v1/admin/dashboard`,
  userList: `${url}/api/v1/admin/userList`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  delete: `${url}/api/v1/plan/delete/`,
  invoiceList: `${url}/api/v1/admin/invoiceDownloadAllByAdmin`,
  staticContentList: `${url}/api/v1/static/staticContentList`,
  addStaticContent: `${url}/api/v1/static/addStaticContent`,
  editStaticData: `${url}/api/v1/static/editStaticContent`,
  viewStatic: `${url}/api/v1/static/viewStaticContent`,

  // bank--------
  addBank: `${url}/api/v1/admin/addBank`,
  listBank: `${url}/api/v1/admin/listBank`,
  editBank: `${url}/api/v1/admin/editBank`,
  deleteBank: `${url}/api/v1/admin/deleteBank`,
  activeBlockBank: `${url}/api/v1/admin/activeBlockBank`,
  reportResult: `${url}/api/v1/admin/reportList`,
  eSimUpdate:  `${url}/api/v1/admin/updateEsimPackages`,
  adminTransactionHisty:  `${url}/api/v1/admin/adminTransactionHistory`,

  // fund----------
  usdtRecord: `${url}/api/v1/admin/listAdminHotWallet`,
  listwithdraw: `${url}/api/v1/admin/listwithdraw`,
  listDeposit: `${url}/api/v1/admin/listDeposit`,
  viewWithdraw: `${url}/api/v1/admin/viewWithdraw`,
  approveRejectWithdraw: `${url}/api/v1/admin/approveRejectWithdraw`,
  viewDeposit: `${url}/api/v1/admin/viewDeposit`,
  approveRejectDeposit: `${url}/api/v1/admin/approveRejectDeposit`,
  listUserTransfer: `${url}/api/v1/admin/listUserTransfer`,
  usdtList: `${url}/api/v1/admin/listUserTransfer`,
  viewuserTransfer: `${url}/api/v1/admin/viewuserTransfer`,
  totalDepositTransferWithdraw: `${url}/api/v1/admin/totalDepositTransferWithdraw`,

  // hot-wallet------------
  listAdminHotWallet: `${url}/api/v1/admin/listAdminHotWallet`,
  listEarning: `${url}/api/v1/admin/listEarning`,
  feeListing: `${url}/api/v1/admin/listfeeSetting`,
  commissionEarningList: `${url}/api/v1/admin/commissionEarningList`,

  // wallet-management------------
  listExchange: `${url}/api/v1/admin/listExchange`,
  viewExchange: `${url}/api/v1/admin/viewExchange`,
  approveRejectExchange: `${url}/api/v1/admin/approveRejectExchange`,

  // ticket-management------------
  listSupportTicket: `${url}/api/v1/admin/listSupportTicket`,
  viewSupportTicket: `${url}/api/v1/admin/viewSupportTicket`,
  replySupportTicket: `${url}/api/v1/admin/replySupportTicket`,

  // feemanage---------
  listfeeSetting: `${url}/api/v1/admin/listfeeSetting`,
  editFeeSetting: `${url}/api/v1/admin/editFeeSetting`,

  // stake-management----------
  listStake: `${url}/api/v1/admin/listStake`,
  viewStake: `${url}/api/v1/admin/viewStake`,
  listStakeFeeSetting: `${url}/api/v1/admin/listStakeFeeSetting`,
  approveRejectStake: `${url}/api/v1/admin/approveRejectStake`,
  cryptoFee: `${url}/api/v1/admin/getFeeCrypto`,

  // transfer-managemnet----------

  listWithdrawByBankOrCard: `${url}/api/v1/admin/listWithdrawByBankOrCard`,
  viewWithdrawByBankOrCard: `${url}/api/v1/admin/viewWithdrawByBankOrCard`,
  approveRejectWithdrawByBankOrCard: `${url}/api/v1/admin/approveRejectWithdrawByBankOrCard`,

  // FAQ------------
  faqList: `${url}/api/v1/faq/faqList`,
  addFAQ: `${url}/api/v1/faq/addFAQ`,
  viewFAQ: `${url}/api/v1/faq/viewFAQ`,
  deleteFAQ: `${url}/api/v1/faq/deleteFAQ`,
  editFAQ: `${url}/api/v1/faq/editFAQ`,

  // senFund------------
  listCoin: `${url}/api/v1/wallet/listCoin`,
  listUserWallet: `${url}/api/v1/admin/listUserWallet`,
  activeBlockCoin: `${url}/api/v1/admin/activeBlockCoin`,

  // senFund------------
  updateCoinPermission: `${url}/api/v1/admin/updateCoinPermission`,

  // announcement---------
  listAnnouncement: `${url}/api/v1/notification/listAnnouncement`,
  addAnnouncement: `${url}/api/v1/notification/addAnnouncement`,
  deleteAnnouncement: `${url}/api/v1/notification/deleteAnnouncement`,
  updateAnnouncement: `${url}/api/v1/notification/updateAnnouncement`,

  // sendMoneyTouser=====
  sendMoneyFromHotWallet: `${url}/api/v1/admin/sendMoneyFromHotWallet`,
  sendMoneyToUser: `${url}/api/v1/admin/sendMoneyToUser`,
  sendGiftToUser: `${url}/api/v1/admin/sendGiftToUser`,
  sendGiftToOneUser: `${url}/api/v1/admin/sendGiftToOneUser`,
  userFundWithdraw: `${url}/api/v1/admin/userFundWithdraw`,
  usdtReject: `${url}/api/v1/admin/approveRejectSendMoneyToWallet

`,
  internalDashBoard: `${url}/api/v1/admin/internalTransferDashboard`,

  ////customCoin
  addToken: `${url}/api/v1/admin/addToken`,
  getToken: `${url}/api/v1/admin/getToken`,
  editToken: `${url}/api/v1/admin/editToken`,
  blockUnblockToken: `${url}/api/v1/admin/blockUnblockToken`,
  getMarketLivePrice: `${url}/api/v1/wallet/getMarketLivePrice`,

  ////saving management
  getSavingManagement: `${url}/api/v1/admin/savingManagement`,
  getUserAcitivities: `${url}/api/v1/admin/userActivitiesInSavingManagement`,
  getAllExpiringSoon: `${url}/api/v1/admin/expiringSoonInSavingManagement`,
  approveUnlockAmountRequest: `${url}/api/v1/admin/approveUnlockAmountRequest`,

  ////finance

  getSubscriptionAnalytics: `${url}/api/v1/admin/getSubscriptionAnalytics`,

  getSubscriptionRecievable: `${url}/api/v1/admin/getSubscriptionRecievable`,
  getSubscriptionRenewalAndPurchase: `${url}/api/v1/admin/getSubscriptionRenewalAndPurchase`,
  getSubscriptionThisMonth: `${url}/api/v1/admin/getSubscriptionThisMonth`,

  // Finance and analytics managemnet
  analyticsWithGraph: `${url}/api/v1/admin/analyticAndFinanceGraph`,

  // for Finance Cards data
  getFinancialAnalytics: `${url}/api/v1/admin/getFinancialAnalytics`,

  ///// salesAndMarketing
  salesAndMarketing: `${url}/api/v1/admin/salesAndMarketing`,

  /// loan Management
  loanManagement: `${url}/api/v1/admin/loanManagement`,

  // money Tranfer Management
  getMoneyTransferManagement: `${url}/api/v1/admin/getMoneyTransferManagement`,
  listUserTransfer: `${url}/api/v1/admin/listUserTransfer`,
  internalTransferCardValues: `${url}/api/v1/admin/internalTransferCardValues`,
  internalTransferLog: `${url}/api/v1/admin/internalTransferLog`,
  internalTransferVolumeSent: `${url}/api/v1/admin/internalTransferVolumeSent`,
  internalTransferVolumeRecieved: `${url}/api/v1/admin/internalTransferVolumeRecieved`,
  saveCommisionRate: `${url}/api/v1/admin/saveCommisionRate`,
  moneyTransferManagement: `${url}/api/v1/admin/moneyTransferManagement`,
  saveBankCountry: `${url}/api/v1/admin/saveBankCountry`,
  getCommisionRate: `${url}/api/v1/admin/getCommisionRate`,
  deleteCommsion: `${url}/api/v1/admin/deleteCommsion`,
  getCountryRate: `${url}/api/v1/admin/getCountryRate`,
  updateReferral: `${url}/api/v1/admin/updateReferral`,
  getUserReferralRanking: `${url}/api/v1/admin/getUserReferralRanking`,
  subscribedReferral: `${url}/api/v1/admin/subscribedReferral`,
  uniqueReferral: `${url}/api/v1/admin/uniqueReferral`,
  activeCustomRefferal: `${url}/api/v1/admin/activeCustomRefferal`,
  deleteActiveCustomRefferal: `${url}/api/v1/admin/deleteActiveCustomRefferal`,
  cardAmount: `${url}/api/v1/admin/cardAmount`,
  getCardAmount: `${url}/api/v1/admin/getCardAmount`,
  getFee: `${url}/api/v1/admin/getFee`,
  subscribedUsersForpushNotification: `${url}/api/v1/admin/subscribedUsersForpushNotification`,
  pushNotification: `${url}/api/v1/admin/pushNotification`,
  lockedBalanceHistory: `${url}/api/v1/admin/lockedBalanceHistory`,
  sendAllUserNotification: `${url}/api/v1/admin/sendAllUserNotification`,

  // for freezing the user
  getFreezUser: `${url}/api/v1/admin/freezeUser`,
  getBanUser: `${url}/api/v1/admin/blockUnblockUser`,

  // For all Sub admin

  addSubAdmin: `${url}/api/v1/admin/addSubAdmin`,
  listSubAdmin: `${url}/api/v1/admin/listSubAdmin`,
  updateSubAdmin: `${url}/api/v1/admin/updateSubAdmin`,

  //  Cards
  cardDashboard: `${url}/api/v1/admin/cardDashboard`,
  moneyTransferCards: `${url}/api/v1/admin/moneyTransferCardValues`,

  // Subcription fee and management

  getSubscription: `${url}/api/v1/user/getSubscription`,
  updateSubscriptionAndFeeMngmt: `${url}/api/v1/admin/updateSubscriptionAndFeeMngmt`,

  // For getUrgentTransactionRequest (Dashboard)
  getUrgentTransactionRequest: `${url}/api/v1/admin/getUrgentTransactionRequest`,

  //  support api
  getlistSupportTicket: `${url}/api/v1/admin/listSupportTicket`,

  // for Static Informaiton pdf

  getStaticData: `${url}/api/v1/static/addStaticContent`,

  // for approve and reject

  approveRejectPickupRequest: `${url}/api/v1/admin/approveRejectPickupRequest`,
  approveRejectInternationalRequest: `${url}/api/v1/admin/approveRejectInternationalRequest`,

  // user list download for a particular user
  invoiceDownload: `${url}/api/v1/user/invoiceDownload`,
  cryptoInvoice: `${url}/api/v1/user/invoiceDownload`,

  //getChatAdmin
  getChatList  : `${url}/api/v1/user/getChatByAdmin`,
  reportUser  : `${url}/api/v1/user/reportUser`,
  approveUserChatReq  : `${url}/api/v1/user/approveUserChatReq`,
  viewChatlist  : `${url}/api/v1/user/viewChat`,
  getInternationalTransactionRequest  : `${url}/api/v1/admin/getInternationalTransactionRequest`,
};
export default ApiConfig;
